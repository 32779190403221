import { render, staticRenderFns } from "./Ic5gGmlcView.vue?vue&type=template&id=428167cc&scoped=true"
import script from "./Ic5gGmlcView.vue?vue&type=script&setup=true&lang=js"
export * from "./Ic5gGmlcView.vue?vue&type=script&setup=true&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "428167cc",
  null
  
)

export default component.exports